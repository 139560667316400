import React from 'react'
import Layout from '../../../templates/Master'
import Media from '../../../modules/Media'
import { Box } from '@mui/material'
import LineBreak from '../../../components/general/LineBreak'
import Heading from '../../../modules/Heading'
import Content from '../../../modules/Content'
import Form from '../../../modules/Form'
import Carousel from '../../../modules/Carousel'
import Share from '../../../modules/Share'
import Table from '../../../modules/Table'


const BidPayCashPage = () => {
    return (
        <Layout>
            <Media media = {[
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_front_top_cb8d6fa4c2.jpg" ]} />
            <Box textAlign="center" my={8}>
                <Heading as='h1' content='Next Minimum Cash Bid Increments for the physical baseball card auction' />
            </Box>
            <LineBreak short/>
            <Table />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>Bidding for the Physical Baseball Card</h2><p>To bid, make offers, or buy items on our site, you need a credit card on file. You do not need to make payment using this card, we accept credit card and PayPal on balances due up to $2,500. If your balance due is more than $2,500 we accept cash (in-person only), check, money order, wire transfer, and ACH payments. For more information about payments please see our <a href='/help'>help page</a>.</p><h3>Bidding over $25,000</h3><p><a href='mailto:support@comicconnect.com'>support@comicconnect.com</a> so that we may approve your account for bidding. This policy was instituted to protect consignors and bidders against bids from fraudulent customer accounts, and to ensure the integrity of the bidding process.</p><h3>Buyer's Premiums</h3><p>A Buyer’s Premium of 15% will be added to the physical card lot to the successful bid. The buyer’s premium is a charge in addition to the final price (i.e. the winning bid) of an auction item.</p><p>Bids may be placed only in whole dollars, periods or commas are not accepted.</p>" />
            <LineBreak short/>
            <Content
                alignText="center"
                content="<h2>Paying with USD for the physical item</h2><p>Payment for the physical card will be through our normal $US payment options including Visa, Master Card, Discover, AMEX, PayPal, checks, money orders, ACH, and wire transfers. However, we do not accept credit cards or PayPal for orders or cumulative outstanding balances above $2,500, and we cannot accept checks from non-U.S. banks. For further details, please see 'Payments' section on the <a href='/help'>Help &amp; FAQ page</a>.</p>" />
            <LineBreak />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' variant='h4' content='Sign up to receive auction updates!' width={['auto', 'auto', 'auto', 'auto', 800]} />
            </Box>
            <Content
                alignText="center"
                content="<p>Don't miss this historic event! Please sign up here to stay up to date and receive sneak previews!</p>"
                width={['auto', 'auto', 'auto', 'auto', 800]}/>
            <Form />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' content='Photos and Video of the Original 1992 Mark Zuckerberg Baseball Card & NFT' />
            </Box>
            <Carousel video media={[
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-with-card.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-day-camp-1992-bear.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_counselor_c92b07296f.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_irvin_simon_c85982ae3a.jpg",
                "comicconnect_mark_zuckerberg_rookie_card_day_camp_1993_a9968c0984.jpg" ]}/>
            <LineBreak />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' width={['auto', 'auto', 'auto', 'auto', 500]} content='Tell your friends about the Mark Zuckerberg baseball card & NFT auction!' />
            </Box>
            <Box textAlign="center" mb={5}>
                <Share />
            </Box>
        </Layout>
    )
}


export default BidPayCashPage
